import { Languages } from "@app/types/multilingual"

interface translation {
    CallDriver: string,
    OrderDetails: string,
    PickupAt: string,
    FinishAt: string 
}

export const tStore : {
    [key in  Languages]: translation;
} = {
    en: {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    fa: {
        CallDriver: 'تماس با راننده',
        OrderDetails: 'جزییات سفارش',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    de: {
        CallDriver: 'Fahrer anrufen',
        OrderDetails: 'Bestelldetails',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    ckb: {
        CallDriver: 'پەیوەندی بە شۆفێرەوە بکە',
        OrderDetails: 'وردەکارییەکانی داواکاری',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    fr: {
        CallDriver: 'Contacter le coursier',
        OrderDetails: 'Détails de la commande',
        PickupAt: 'Récupéré à',
        FinishAt: 'Terminé à',
    },
    ar: {
        CallDriver: 'اتصل بالسائق',
        OrderDetails: 'تفاصيل الطلب',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    zh: {
        CallDriver: '呼叫司机',
        OrderDetails: '订单详细信息',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    ro: {
        CallDriver: 'Sună curierul',
        OrderDetails: 'Comanda Detalii',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    nl: {
        CallDriver: 'Bel koerier',
        OrderDetails: 'Bestel Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    fi: {
        CallDriver: 'Soita Kuljettajalle',
        OrderDetails: 'Tilauksen tiedot',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    es: {
        CallDriver: 'Llamar al conductor',
        OrderDetails: 'Detalles del pedido',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    it: {
        CallDriver: `Chiama l'autista`,
        OrderDetails: `Dettagli dell'ordine`,
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    az: {
        CallDriver: `Sürücüyə zəng et`,
        OrderDetails: `Sifariş detalları`,
        PickupAt: 'Götürmək',
        FinishAt: 'Bitirmək',
    },
    ru: {
        CallDriver: 'Позвонить водителю',
        OrderDetails: 'Order Details',
        PickupAt: 'Взять',
        FinishAt: 'Завершить',
    },
    tr: {
        CallDriver: `Sürücüyə zəng et`,
        OrderDetails: `Sifariş detalları`,
        PickupAt: 'Götürmək',
        FinishAt: 'Bitirmək',
    },
    pt: {
        CallDriver: 'Ligar para entregador',
        OrderDetails: 'Order Details',
        PickupAt: 'Recolha em',
        FinishAt: 'Acabar em',
    },
    el: {
        CallDriver: 'Κλήση Οδηγού',
        OrderDetails: 'Λεπτομέρειες Παραγγελίας',
        PickupAt: 'Παραλαβή στις',
        FinishAt: 'Ολοκλήρωση σε',
    },
    'de-AT': {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    ku: {
        CallDriver: 'پەیوەندیکردن لەگەڵ شۆفێر',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    he: {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    hi: {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    },
    ms: {
        CallDriver: 'Call Driver',
        OrderDetails: 'Order Details',
        PickupAt: 'Pickup at',
        FinishAt: 'Finish at',
    }
}

export const t = (key : keyof translation , language : Languages)=>{
    if(tStore[language] && tStore[language][key]) return tStore[language][key]
    else return tStore['en'][key]
}